$(document).ready(initPage);

function initPage() {
  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }
  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });
  $('.toggler').click(function () {
    $('body').toggleClass('is-menu');
  });

  // Form

  function toggleNotEmptyClass($input) {
    $input
      .closest('.form-group')
      .toggleClass('not-empty', $input.val().trim() !== '');
  }

  $('.form-control').each(function () {
    toggleNotEmptyClass($(this));
  });

  $('.form-control')
    .on('focusin', function () {
      $(this).closest('.form-group').addClass('focused');
    })
    .on('focusout input change', function () {
      var $input = $(this);
      $input.closest('.form-group').removeClass('focused');
      toggleNotEmptyClass($input);
    });

  $('select').selectric({
    maxHeight: 400,
    expandToItemText: true,
  });

  // Home

  const locGallerySwiper = new Swiper('.swiper--loc-gallery', {
    slidesPerView: '1',
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      prevEl: '.section-loc-gallery .swiper-prev',
      nextEl: '.section-loc-gallery .swiper-next',
    },
  });

  const newsSwiper = new Swiper('.swiper--news', {
    slidesPerView: '4',
    loop: true,
    spaceBetween: 42,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 42,
      },
    },
    navigation: {
      prevEl: '.section-news .swiper-prev',
      nextEl: '.section-news .swiper-next',
    },
  });

  const reviewsSwiper = new Swiper('.swiper--reviews', {
    slidesPerView: '1',
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      prevEl: '.section-reviews .swiper-prev',
      nextEl: '.section-reviews .swiper-next',
    },
  });

  $('.video-content .btn').on('click', function () {
    var $videoContent = $(this).closest('.video-content');
    var $iframe = $videoContent.find('iframe');
    var videoSrc = $iframe.attr('src');

    $iframe.attr('src', videoSrc + '&autoplay=1');

    // Hide the preview image and button
    $videoContent.addClass('is-active');

    // Show the iframe
    $iframe.show();
  });

  // About

  const locSmSwiper = new Swiper('.swiper--loc-sm', {
    slidesPerView: '4',
    loop: true,
    spaceBetween: 36,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 36,
      },
    },
    navigation: {
      prevEl: '.section-loc-sm .swiper-prev',
      nextEl: '.section-loc-sm .swiper-next',
    },
  });

  // Location

  const heroSwiper = new Swiper('.swiper--hero', {
    slidesPerView: '1',
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      prevEl: '.section-hero--loc .swiper-prev',
      nextEl: '.section-hero--loc .swiper-next',
    },
  });
}
